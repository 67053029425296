import React from "react"

function NextJsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 1024 1024">
      <path
        d="M4785 10234c-22-2-92-9-155-14-1453-131-2814-915-3676-2120-480-670-787-1430-903-2235-41-281-46-364-46-745s5-464 46-745C329 2454 1696 840 3550 242c332-107 682-180 1080-224 155-17 825-17 980 0 687 76 1269 246 1843 539 88 45 105 57 93 67-8 6-383 509-833 1117l-818 1105-1025 1517c-564 834-1028 1516-1032 1516-4 1-8-673-10-1496-3-1441-4-1499-22-1533-26-49-46-69-88-91-32-16-60-19-211-19h-173l-46 29c-30 19-52 44-67 73l-21 45 2 2005 3 2006 31 39c16 21 50 48 74 61 41 20 57 22 230 22 204 0 238-8 291-66 15-16 570-852 1234-1859s1572-2382 2018-3057l810-1227 41 27c363 236 747 572 1051 922 647 743 1064 1649 1204 2615 41 281 46 364 46 745s-5 464-46 745c-278 1921-1645 3535-3499 4133-327 106-675 179-1065 223-96 10-757 21-840 13zm2094-3094c48-24 87-70 101-118 8-26 10-582 8-1835l-3-1798-317 486-318 486v1307c0 845 4 1320 10 1343 16 56 51 100 99 126 41 21 56 23 213 23 148 0 174-2 207-20z"
        transform="matrix(.1 0 0 -.1 0 1024)"
      ></path>
      <path
        d="M7843 789c-35-22-46-37-15-20 22 13 58 40 52 41-3 0-20-10-37-21zM7774 744c-18-14-18-15 4-4 12 6 22 13 22 15 0 8-5 6-26-11zM7724 714c-18-14-18-15 4-4 12 6 22 13 22 15 0 8-5 6-26-11zM7674 684c-18-14-18-15 4-4 12 6 22 13 22 15 0 8-5 6-26-11zM7598 644c-38-20-36-28 2-9 17 9 30 18 30 20 0 7-1 6-32-11z"
        transform="matrix(.1 0 0 -.1 0 1024)"
      ></path>
    </svg>
  )
}

export default NextJsIcon
