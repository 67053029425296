import React from "react"

function ApolloIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 256"
    >
      <path
        fill="#112B49"
        d="M160.227 178.519h27.63L143.75 64.049h-30.549l-44.107 114.47h27.632l7.208-19.39h41.675l-7.544-21.456h-27.44l17.85-49.254 31.752 90.1zm91.112-84.751a6.641 6.641 0 00-8.185-4.627 6.648 6.648 0 00-4.628 8.183A114.646 114.646 0 01242.704 128c0 63.248-51.456 114.702-114.704 114.702-63.248 0-114.703-51.454-114.703-114.702C13.297 64.751 64.752 13.296 128 13.296c26.793 0 52.718 9.518 73.179 26.456a15.938 15.938 0 00-1.238 6.173c0 8.835 7.162 15.997 15.997 15.997s15.997-7.162 15.997-15.997-7.162-15.997-15.997-15.997c-1.701 0-3.338.271-4.876.763C188.022 11.056 158.513 0 128 0 57.421 0 0 57.42 0 128c0 70.579 57.421 127.999 128 127.999 70.579 0 128-57.42 128-127.999a127.95 127.95 0 00-4.661-34.232z"
      ></path>
    </svg>
  )
}

export default ApolloIcon
