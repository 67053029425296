import React from "react"

function WordPressIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 430 430"
    >
      <path d="M0 215.059c0 118.786 96.274 215.059 215.059 215.059 118.759 0 215.059-96.272 215.059-215.059C430.117 96.303 333.817 0 215.059 0 96.274 0 0 96.303 0 215.059zm16.572 0c0-28.775 6.196-56.082 17.191-80.78l94.679 259.398c-66.2-32.136-111.87-100.061-111.87-178.618zm198.487 198.482c-19.48 0-38.3-2.838-56.077-8.06l59.568-173.032 61.008 167.128c.396.989.887 1.862 1.4 2.702-20.61 7.285-42.802 11.262-65.899 11.262zm160.407-202.204c11.323-28.317 15.112-50.955 15.112-71.084 0-7.309-.49-14.09-1.372-20.421 15.495 28.287 24.307 60.711 24.307 95.232 0 73.235-39.671 137.151-98.662 171.538l60.615-175.265zm-42.797-61.038c7.533 13.212 16.377 30.203 16.377 54.742 0 17-5.055 38.366-15.107 64.175l-19.807 66.123-71.733-213.328c11.976-.651 22.71-1.895 22.71-1.895 10.697-1.27 9.418-16.96-1.246-16.372 0 0-32.161 2.539-52.913 2.539-19.508 0-52.264-2.539-52.264-2.539-10.702-.588-11.943 15.751-1.272 16.372 0 0 10.146 1.244 20.846 1.895l30.894 84.727-43.424 130.239-72.295-214.966c11.974-.651 22.736-1.895 22.736-1.895 10.704-1.27 9.395-16.96-1.272-16.372 0 0-32.135 2.539-52.913 2.539-3.72 0-8.093-.098-12.757-.231 35.5-53.884 96.506-89.475 165.831-89.475 51.674 0 98.723 19.767 134.02 52.131-.849-.067-1.698-.168-2.548-.168-19.541 0-33.342 16.972-33.342 35.209.003 16.373 9.431 30.21 19.479 46.55z"></path>
    </svg>
  )
}

export default WordPressIcon
